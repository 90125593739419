import { NavEntry, SocialEntry } from 'sparkswap-uikit'

export const HOME_URL = process.env.REACT_APP_FARMS_URL || 'https://sparkswap.xyz';
export const EXCHANGE_URL = '/swap';
export const LIQUIDITY_URL = '/pool';
export const BRIDGE_URL = '/bridge';
export const SPARK_404_URL = '/spark404';

export const FARMS_URL = `${HOME_URL}/#/farms`;
export const VAULTS_URL = 'https://vaults.sparkswap.xyz';
export const PERPETUALS_URL = process.env.REACT_APP_PERPETUALS_URL || 'https://perps.sparkswap.xyz';
export const MARKETPLACE_URL = 'https://marketplace.sparkswap.xyz';
export const SLP_VAULT_URL = `${PERPETUALS_URL}/vault`;
export const STATS_URL = `${PERPETUALS_URL}/stats`;
export const DAILY_TRADES_URL = `${PERPETUALS_URL}/daily-trades`;
export const DOCS_URL = 'https://empmoneyv2.gitbook.io/sparkswap/about/sparkswap-whitepaper';
export const CHART_URL = 'https://dexscreener.com/pulsechain/0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52';
export const AUDIT_URL = 'https://fleek.ipfs.io/ipfs/bafybeiawdbrvhwwpvmuc6agcxsxpjrigv3bzjtfmwei2zftrjsf3gv4zlm';
export const SPARKLER_URL = `${HOME_URL}/#/sparkler`

export const links: NavEntry[] = [
  {
    label: 'Trade',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: EXCHANGE_URL
      },
      {
        label: 'Liquidity',
        href: LIQUIDITY_URL
      }
    ]
  },
  {
    label: 'Spark404',
    href: SPARK_404_URL
  },
  {
    label: 'Bridge',
    href: BRIDGE_URL
  },
  {
    label: 'Farms',
    href: FARMS_URL
  },
  {
    label: 'Vaults',
    href: VAULTS_URL,
    openTab: true
  },
  {
    label: 'Sparkler',
    href: SPARKLER_URL,
  },
  {
    label: 'Perpetuals',
    items: [
      {
        label: 'Perpetuals',
        href: PERPETUALS_URL,
      },
      {
        label: 'SLP Vault',
        href: SLP_VAULT_URL,
      },
      {
        label: 'Stats',
        href: STATS_URL,
      },
      {
        label: 'Daily Trades',
        href: DAILY_TRADES_URL,
      }
    ],
  },
  {
    label: 'Marketplace',
    initialOpenState: true,
    items: [

      {
        label: 'Collection',
        href: `${MARKETPLACE_URL}/collections/1`,
      },
      {
        label: 'Dashboard',
        href: `${MARKETPLACE_URL}/dashboard`,
      },
    ]
  },
]

export const socials: SocialEntry[] = [
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://x.com/sparkswap_",
  },
  {
    label: "Youtube",
    icon: "YoutubeIcon",
    href: "https://www.youtube.com/@EmpMoney/videos",
  },
  {
    label: "Discord",
    icon: "DiscordIcon",
    href: "https://discord.com/invite/sparkswap",
  },
  {
    label: "Telegram",
    icon: "TelegramIcon",
    href: "https://t.me/dexswapofficial",
  },
];

export const docs: { title: string, href: string }[] = [
  {
    title: 'Docs',
    href: 'https://empmoneyv2.gitbook.io/sparkswap/about/sparkswap-whitepaper',
  },
  {
    title: 'Chart',
    href: 'https://dexscreener.com/pulsechain/0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52',
  },
  {
    title: 'Audit',
    href: 'https://fleek.ipfs.io/ipfs/bafybeiawdbrvhwwpvmuc6agcxsxpjrigv3bzjtfmwei2zftrjsf3gv4zlm',
  },
];

